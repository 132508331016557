/** форма бренда
 *
 */
import _ from "lodash";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { LocalesValues, PageTitle, CurrenciesValues, ImagePreview } from "components/standart";
import { Uploader, AudioPreview, Text2Mp3Button } from "components/standart";
import { FloatCountries } from "components/special/contents";

import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { Alert } from "reactstrap";

import { adminAPI } from "redux/api";
import { SwitchValue } from "components/special/forms";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentForm = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs, content, setContent } = props;
  const params = useParams();
  const [dataSaved, setDataSaved] = useState(false);

  const changeContent = setOnChange(content, setContent);
  breadCrumbs.push({
    title: <IntlMessages id="Contents.Form" />,
    url,
  });

  const successAlertRef = useRef(null);

  const saveHandler = () => {
    const onSuccess = (body) => {
      setContent(body);
      setDataSaved(true);
      successAlertRef.current.scrollIntoView();
    };

    const onRequest = () => {
      setDataSaved(false);
    };

    const onFailure = () => {
      setDataSaved(false);
    };

    props.saveContent({ params, body: content }, { onSuccess, onFailure, onRequest });
  };

  return (
    <div>
      <PageTitle {...breadCrumbs} />

      <div className="row">
        <div className="col-12">
          <div className="roe-card-style">
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="name" />
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    name="name"
                    value={content.name || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.country" />
                </label>
                <div className="col-8">
                  <FloatCountries
                    _id={content.countryId}
                    multi={false}
                    showTitle={true}
                    showCount={false}
                    className="btn-sm"
                    successHandler={([countryId]) => {
                      setContent({ ...content, countryId });
                    }}
                  />
                </div>
              </div>

              {content.countryId ? (
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id="Categories.countryDefault" />
                  </label>
                  <div className="col-8 my-auto">
                    <SwitchValue
                      value={content.countryDefault}
                      name="countryDefault"
                      onChange={changeContent}
                    />
                  </div>
                </div>
              ) : null}

              {/* 
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.alias" />
                </label>
                <div className="col-8">
                  <input
                    type="text"
                    name="alias"
                    value={content.alias || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              */}

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.castTitle" />
                </label>
                <div className="col">
                  <input
                    type="text"
                    name="castTitle"
                    value={content.castTitle || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.soundText" />
                </label>
                <div className="col">
                  <input
                    type="text"
                    name="soundText"
                    value={content.soundText || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                  />
                </div>

                <div className="col-1">
                  <Text2Mp3Button
                    value={content?.soundText}
                    onSuccess={(file) => setContent({ ...content, soundFileId: file._id })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.soundFileId" />
                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={([file]) => setContent({ ...content, soundFileId: file._id })}
                    accept="*.mp3"
                    multiple={false}
                  />
                </label>
                <div className="col">
                  {content.soundFileId && (
                    <AudioPreview
                      file={{ _id: content.soundFileId }}
                      deleteHandler={() => {
                        setContent({ ...content, soundFileId: null });
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.templateFileId" />
                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={([file]) =>
                      setContent({ ...content, templateFileId: file._id })
                    }
                    imagesOnly="true"
                    multiple={false}
                  />
                </label>
                <div className="col">
                  {content.templateFileId && (
                    <ImagePreview
                      file={{ _id: content.templateFileId }}
                      deleteHandler={() => {
                        setContent({ ...content, templateFileId: null });
                      }}
                    />
                  )}
                </div>
              </div>

              {/* временно скрыто */}
              {false ? (
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id="Categories.templateStoryFileId" />
                    <Uploader
                      className="btn-success btn-sm pull-right"
                      successHandler={([file]) =>
                        setContent({ ...content, templateStoryFileId: file._id })
                      }
                      imagesOnly="true"
                      multiple={false}
                    />
                  </label>
                  <div className="col">
                    {content.templateStoryFileId && (
                      <ImagePreview
                        file={{ _id: content.templateStoryFileId }}
                        deleteHandler={() => {
                          setContent({ ...content, templateStoryFileId: null });
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : null}

              {/* 
              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="description" />
                </label>
                <div className="col-8">
                  <textarea
                    type="text"
                    name="description"
                    rows={3}
                    value={content.description || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.castsSchedules" />
                </label>
                <div className="col">
                  <input
                    type="text"
                    name="castsSchedules"
                    value={content.castsSchedules || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                  />
                </div>
              </div>
               */}

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.imagePrompt" />
                </label>
                <div className="col">
                  <input
                    type="text"
                    name="imagePrompt"
                    value={content.imagePrompt || ""}
                    onChange={changeContent}
                    className="form-control react-form-input"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="localesName" />
                  <LocalesValues.AddButton
                    className="btn-sm float-right"
                    values={content.localesName}
                    changeValues={(localesName) => setContent({ ...content, localesName })}
                  />
                </label>
                <div className="col-8">
                  <LocalesValues
                    values={content.localesName}
                    changeValues={(localesName) => setContent({ ...content, localesName })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="Categories.prices" />

                  <CurrenciesValues.AddButton
                    className="btn-sm float-right"
                    values={content.prices}
                    changeValues={(prices) => setContent({ ...content, prices })}
                  />
                </label>
                <div className="col">
                  <CurrenciesValues
                    values={content.prices}
                    changeValues={(prices) => setContent({ ...content, prices })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-4 col-form-label">
                  <IntlMessages id="tables.enabled" />
                </label>
                <div className="col-8 my-auto">
                  <SwitchValue value={content.enabled} onChange={changeContent} />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-4 col-auto">
                  <button className="form-control c-primary" type="button" onClick={saveHandler}>
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormButton = (props) => {
  const { contentForm, content } = props;
  return (
    <Link to={contentForm(content)} className="btn btn-secondary btn-sm">
      <i className="fa fa-edit" />
    </Link>
  );
};

export default connect(null, {
  saveContent: api.patch(apiUrls.categoriesInfo),
})(ContentForm);
