import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { DateTimeGroupsSelector, Finder } from "components/standart";
import IntlMessages from "util/intlMessages";
import { useIntl } from "react-intl";

export const scales = ["week", "month", "year"];

export const ScaleSelector = (props) => {
  const intl = useIntl();
  const scales = ["week", "month", "year"];
  const [query, setQuery] = useState({ ...props.query });
  const [currentScale, setCurrentScale] = useState(scales[0]);
  const baseTime = props.baseTime || "baseTime";
  const maxDateTime = props.maxDateTime || "maxDateTime";
  const minDateTime = props.minDateTime || "minDateTime";

  const setQueryValue = (queryAttr) => (value) => {
    setQuery({ ...query, [queryAttr]: value });
  };

  useEffect(() => {
    setQuery({
      ...query,
      scale: currentScale,
      baseTime: currentScale === "year" ? "toStartOfMonth" : "toStartOfDay",
      minDateTime: moment().subtract(1, currentScale).format(),
    });
    // props.setQuery && props.setQuery(query);
  }, [currentScale]);

  useEffect(() => {
    props.setQuery && props.setQuery(query);
  }, [query]);

  return (
    <div className="text-right">
      {/* 
        <span className="mr-3">
          <IntlMessages id="TimeScales" />
        </span>
           */}
      {_.map(scales, (scale, index) => {
        const isActive = scale === currentScale;
        return (
          <span
            key={index}
            className={`btn btn-sm mr-3 ${isActive ? "btn-info" : "border-secondary"}`}
            // onClick={() => props.setScale(scale)}
            onClick={() => setCurrentScale(scale)}
          >
            <IntlMessages id={`TimeScales.${scale}`} />
          </span>
        );
      })}
    </div>
  );

  return (
    <div className="float-right">
      {/* 
      <span className="mr-3">
        <IntlMessages id="TimeScales" />
      </span>
      */}
      {/* 
         тут на самом деле конструкции: 
         - селектор по списку значений
         - интервал дат
         - добавление фильтров по каналам/партнерам/темам

         изменение значений влияет на `query` и привязано к своим собственным именам
         придется сделать немного кривовато, но максимально похоже на правду
      */}
      <div className="d-flex">
        <div className="mr-2" style={{ width: "250px" }}>
          <DateTimeGroupsSelector
            value={query[baseTime]}
            name={baseTime}
            setValue={setQueryValue(baseTime)}
          />
        </div>
        <div>
          <Finder
            className="mr-1 d-inline"
            icon="fa-calendar-alt"
            isActive={query[maxDateTime] || query[minDateTime]}
          >
            <Finder.datePicker
              name={minDateTime}
              {...{ query, setQuery }}
              placeholder={intl.formatMessage({ id: "minDate" })}
            />
            <br />
            <Finder.datePicker
              name={maxDateTime}
              {...{ query, setQuery }}
              placeholder={intl.formatMessage({ id: "maxDate" })}
            />
          </Finder>
        </div>
      </div>
      {/* {JSON.stringify(query)} */}

      {/* 
      {_.map(props.scales, (scale, index) => {
        const isActive = scale === props.scale;
        return (
          <span
            key={index}
            className={`btn btn-sm mr-3 ${isActive ? "btn-info" : "border-secondary"}`}
            onClick={() => props.setScale(scale)}
          >
            <IntlMessages id={`TimeScales.${scale}`} />
          </span>
        );
      })}
       */}
    </div>
  );
};
