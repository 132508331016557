/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import qs from "query-string";
import moment from "moment";

import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Pager, TableLoading } from "components/standart";
import { ShowDuration, ShowShortDuration } from "components/special/durations";

import { useIntl } from "react-intl";

import { limit } from "variables";

import { apiUrls, infoLink } from "./init";

const { api } = adminAPI.actions;

const LogsTable = (props) => {
  const params = useParams();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [data, setData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  useEffect(() => {
    setQuery({ ...params, limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setData(body);
    };
    const onRequest = () => {
      setData({ total: -1 });
    };

    query.limit && props.getContent({ params, query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <Table responsive style={{ position: "relative" }}>
      <thead>
        <tr>
          <th>
            <IntlMessages id="Channels.Translations.Logs.messagesCount" />
          </th>
          <th>
            <IntlMessages id="Channels.Translations.Logs.duration" />
          </th>
          <th>
            <IntlMessages id="Channels.Translations.Logs.completedAt" />
          </th>
          <th>
            <IntlMessages id="Channels.Translations.Logs.finishedAt" />
          </th>
          {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
          <th className="text-right text-nowrap -col-4">
            {/* <Finder.search {...{ query, url }} className="mr-1" /> */}
          </th>
        </tr>
      </thead>
      {data.total < 0 && <TableLoading />}
      <tbody>
        {_.map(data.data, (value, key) => {
          return (
            <tr key={key}>
              <td>
                <Link to={infoLink(value)}>{_.size(value.messagesId)}</Link>
              </td>
              <td>{ShowShortDuration(value.duration)}</td>

              <td>{value.completedAt ? moment(value.completedAt).format(dateTimeFormat) : null}</td>

              <td>{value.finishedAt ? moment(value.finishedAt).format(dateTimeFormat) : null}</td>

              <td className="text-right"></td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="99">
            <Pager query={query} url={url} total={data.total} />
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.list),
})(LogsTable);
