import { path as parentPath } from "../init";

export const path = "channels_translations";

export const apiUrls = {
  list: "/api/channels/translations",
  // reload: "/api/channels/translations/reload",
  info: "/api/channels/translations/translation_{translationId}",
  generateCover: "/api/channels/translations/translation_{translationId}/createCover",
  reload: "/api/channels/translations/translation_{translationId}/reload",
  forceStart: "/api/channels/translations/translation_{translationId}/forceStart",
};

export const infoLink = (translation) => {
  return `${parentPath}/${path}/translation_${translation._id}`;
};

export const formLink = (translation) => {
  return `${infoLink(translation)}/form`;
};
