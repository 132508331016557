import _ from "lodash";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Alert } from "reactstrap";
import { SoundMessage, SwitchValue } from "components/special/forms";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import ReactQuill from "react-quill";
import { Uploader, ImagePreview, AudioPreview, Text2Mp3Button } from "components/standart";
import { apiUrls } from "./init";
import { FloatCategories, FloatPartners } from "components/special/contents";

const { api } = adminAPI.actions;

const ChannelData = (props) => {
  const { channel, setChannel } = props;
  const changeChannel = setOnChange(channel, setChannel);

  const [imageLoading, setImageLoading] = useState(false);
  const getImageHandler = () => {
    const params = { channelId: channel._id };
    props.getImage(
      { params },
      {
        onSuccess(data) {
          // установим файл из ссылки
          setChannel({ ...channel, fileId: data._id });
          setImageLoading(false);
        },
        onFailure() {
          setImageLoading(false);
        },
        onRequest() {
          setImageLoading(true);
        },
      }
    );
  };

  const [imageGenerating, setImageGenerating] = useState(false);
  const createCoverHandler = () => {
    const params = { channelId: channel._id };
    props.createCover(
      { params },
      {
        onSuccess(data) {
          // установим файл из ссылки
          setChannel({ ...channel, templateFileId: data._id });
          setImageGenerating(false);
        },
        onFailure() {
          setImageGenerating(false);
        },
        onRequest() {
          setImageGenerating(true);
        },
      }
    );
  };

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.alias" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="alias"
            value={channel.alias || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
            // onFocus={() => setValidChannel(false)}
            // onBlur={checkValidChannel}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.name" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="name"
            value={channel.name || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.searchName" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="searchName"
            value={channel.searchName || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.file" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setChannel({ ...channel, fileId: file._id })}
            imagesOnly="true"
            multiple={false}
          />
          <button className="btn btn-sm btn-warning pull-right mr-2" onClick={getImageHandler}>
            <i className={`fa fa-sync-alt ${imageLoading ? "fa-spin" : ""}`} />
          </button>
        </label>
        <div className="col-8">
          {channel.fileId ? (
            <ImagePreview
              file={{ _id: channel.fileId }}
              deleteHandler={() => {
                setChannel({ ...channel, fileId: null });
              }}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group row">
        <span className="col-4 col-form-label">
          <IntlMessages id="Channels.templateFileId" />
          <span className="pull-right">
            <button className="btn btn-sm btn-warning mr-2" onClick={createCoverHandler}>
              <i className={`fa fa-sync-alt ${imageGenerating ? "fa-spin" : ""}`} />
            </button>
            <Uploader
              className="btn-success btn-sm"
              successHandler={([file]) => setChannel({ ...channel, templateFileId: file._id })}
              imagesOnly="true"
              multiple={false}
            />
          </span>
        </span>
        <div className="col-8">
          {channel.fileId ? (
            <ImagePreview
              file={{ _id: channel.templateFileId }}
              deleteHandler={() => {
                setChannel({ ...channel, templateFileId: null });
              }}
            />
          ) : null}
        </div>
      </div>

      {/* временно скрыто */}
      {false ? (
        <div className="form-group row">
          <label className="col-4 col-form-label">
            <IntlMessages id="Channels.templateStoryFileId" />
            <Uploader
              className="btn-success btn-sm pull-right"
              successHandler={([file]) => setChannel({ ...channel, templateStoryFileId: file._id })}
              imagesOnly="true"
              multiple={false}
            />
          </label>
          <div className="col">
            {channel.templateStoryFileId && (
              <ImagePreview
                file={{ _id: channel.templateStoryFileId }}
                deleteHandler={() => {
                  setChannel({ ...channel, templateStoryFileId: null });
                }}
              />
            )}
          </div>
        </div>
      ) : null}

      {/*
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.castsSchedules" />
        </label>
        <div className="col">
          <input
            type="text"
            name="castsSchedules"
            value={channel.castsSchedules || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>
      </div>
      */}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.soundName" />
        </label>
        <div className="col">
          <input
            type="text"
            name="soundName"
            value={channel.soundName || ""}
            onChange={changeChannel}
            className="form-control react-form-input"
            autoComplete="off"
          />
        </div>

        <div className="col-1">
          <Text2Mp3Button
            value={channel?.soundName}
            onSuccess={(file) => setChannel({ ...channel, soundFileId: file._id })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.soundFileId" />
          <Uploader
            className="btn-success btn-sm pull-right"
            successHandler={([file]) => setChannel({ ...channel, soundFileId: file._id })}
            accept="*.mp3"
            multiple={false}
          />
        </label>
        <div className="col">
          {channel.soundFileId && (
            <AudioPreview
              file={{ _id: channel.soundFileId }}
              deleteHandler={() => {
                setChannel({ ...channel, soundFileId: null });
              }}
            />
          )}
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.category" />
        </label>
        <div className="col-8 my-auto">
          <FloatCategories
            _id={channel.categoryId}
            query={{ everything: false }}
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([categoryId]) => {
              setChannel({ ...channel, categoryId });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.introSoundMessage" />
        </label>
        <div className="col-8 my-auto">
          <SoundMessage data={channel} setData={setChannel} attrName="introSoundMessage" />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.partner" />
        </label>
        <div className="col-8 my-auto">
          <FloatPartners
            _id={channel.partnerId}
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([partnerId]) => {
              setChannel({ ...channel, partnerId });
            }}
          />
        </div>
      </div>

      <div className="form-group row" style={{ height: 150 }}>
        <label className="col-4 col-form-label">
          <IntlMessages id={`Channels.description`} />
        </label>
        <div className="col-8">
          <ReactQuill
            value={channel.description || ""}
            style={{ height: 100 }}
            onChange={(description) => {
              setChannel({ ...channel, description });
            }}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.approveMessages" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue
            value={channel.approveMessages}
            name="approveMessages"
            onChange={changeChannel}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.playlistDefault" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue
            value={channel.playlistDefault}
            name="playlistDefault"
            onChange={changeChannel}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.playlistCategory" />
        </label>
        <div className="col-8 my-auto">
          <SwitchValue
            value={channel.playlistCategory}
            name="playlistCategory"
            onChange={changeChannel}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getImage: api.get(apiUrls.getImage),
  createCover: api.get(apiUrls.generateCover),
})(ChannelData);
