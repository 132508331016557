/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useRouteMatch, useLocation, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Alert, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import qs from "query-string";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Uploader, ImagePreview, VideoPreview, AudioPreview } from "components/standart";
import { useIntl } from "react-intl";
import { LoadingIcon } from "components/special";
import { ShowDuration, ShowShortDuration } from "components/special/durations";
import ChannelMessageForm from "../../Channels/Messages/Form";

import { apiUrls } from "./init";
import { useToggle } from "react-use";

const { api } = adminAPI.actions;

const getDailyCover = api.get(apiUrls.dailyCover);

export const DailyCoverData = (props) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const [currentCover, setCurrentCover] = useState({});
  const [isError, setError] = useState(null);

  const getDataHandler = () => {
    dispatch(
      getDailyCover(
        {},
        {
          onSuccess(body) {
            setCurrentCover(body);
          },
          onRequest() {},
          onFailure() {
            setError(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    getDataHandler();
  }, []);

  if (isError) {
    return <Alert className="alert-danger">Ошибка генерации изображения</Alert>;
  }

  return currentCover._id ? (
    <div>
      <div>
        <div className="d-flex">
          <div className="px-2">
            {currentCover.fileId ? (
              <ImagePreview file={{ _id: currentCover.fileId }} style={{ height: "200px" }} />
            ) : null}
          </div>
          <div className="px-2">
            {currentCover.resultFileId ? (
              <ImagePreview file={{ _id: currentCover.resultFileId }} style={{ height: "200px" }} />
            ) : null}
          </div>
        </div>
        <div className="py-3">
          <RefreshImageButton cover={currentCover} successHandler={setCurrentCover} />
        </div>
      </div>

      <div className="py-2">{currentCover.prompt?.text}</div>
    </div>
  ) : (
    <LoadingIcon />
  );
};

const setDailyCover = api.post(apiUrls.dailyCover);
export const RefreshImageButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { successHandler } = props;
  const [body, setBody] = useState({});
  const [modalOpen, toggleModal] = useToggle(false);

  const changeBody = setOnChange(body, setBody);

  const submitHandler = () => {
    toggleModal();
    // const confirmed = window.confirm("Обновить изображение?");
    // const body = { prompt };
    dispatch(
      setDailyCover(
        { body },
        {
          onSuccess(body) {
            successHandler(body);
            setLoading(false);
          },
          onRequest() {
            setBody({});
            setLoading(true);
          },
        }
      )
    );
  };

  return (
    <>
      <button className="btn btn-sm btn-warning" onClick={toggleModal}>
        <i className={`fa fa-sync ${isLoading ? "fa-spin" : ""} mr-2`} />
        <IntlMessages id="OpenAI.Casts.refreshCover" />
      </button>
      {modalOpen ? (
        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="OpenAI.Casts.refreshCoverPrompt" />
          </ModalHeader>
          <ModalBody>
            <textarea
              className="form-control"
              name="prompt"
              rows={2}
              value={body.prompt || ""}
              onChange={changeBody}
            />
            <br />
            <button className="btn btn-primary" onClick={submitHandler}>
              <IntlMessages id="confirm" />
            </button>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};
