import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { adminAPI } from "redux/api";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ScoreMessages = (props) => {
  const params = useParams();

  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  // const changeUser = setOnChange(newUser, setNewUser);

  const syncHandler = (req) => {
    const res = {
      onSuccess(body) {
        props.refreshHandler();
        setLoading(false);
      },
      onRequest() {
        setLoading(true);
        setError(null);
      },
      onFailure({ message }) {
        setError(message);
        setLoading(false);
      },
    };

    const confirmed = window.confirm("Оценить неоцененные сообщения?");
    if (confirmed) {
      props.scoreMessages({ params }, res);
    }
  };

  return (
    <>
      <button
        className={`btn btn-sm btn-secondary ${props.className}`}
        onClick={syncHandler}
        disabled={loading}
      >
        <i className={`fa ${loading ? "fa-spinner fa-spin" : "fa-balance-scale-right"}`} />
      </button>
    </>
  );
};

export default connect(null, {
  scoreMessages: api.post(apiUrls.scoreMessages),
})(ScoreMessages);
