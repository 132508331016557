import _ from "lodash";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { useRouteMatch } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

import { connect } from "react-redux";
import { Alert } from "reactstrap";

import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { Uploader, ImagePreview } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";

const { api } = adminAPI.actions;
const MetaData = (props) => {
  const { url } = useRouteMatch();
  const { envStore, breadCrumbs } = props;
  const [metadata, setMetadata] = useState({});

  const [localeId, setLocaleId] = useState(envStore.locales[0]?._id);

  useEffect(() => {
    props.getMetaData(
      { query: { localeId } },
      {
        onRequest() {
          setMetadata({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setMetadata(body);
        },
      }
    );
  }, [localeId]);

  breadCrumbs.push({
    title: <IntlMessages id="Www.Meta" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeMeta = setOnChange(metadata, setMetadata);

  const uploadFilesHandler =
    (file) =>
    ([fileData]) => {
      changeMeta({ target: { name: file, value: fileData._id } });
    };

  const saveSettingsHandler = () => {
    props.saveMetaData(
      {
        query: { localeId },
        body: { ...metadata },
      },
      {
        onSuccess(body) {
          setMetadata(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      <Nav tabs style={{ cursor: "pointer" }}>
        {_.map(envStore.locales, (locale, index) => {
          return (
            <Fragment key={index}>
              <NavItem>
                <span
                  className={`nav-link ${localeId === locale._id ? "active" : ""}`}
                  onClick={() => setLocaleId(locale._id)}
                >
                  {locale.name}
                </span>
              </NavItem>
            </Fragment>
          );
        })}
      </Nav>

      {metadata._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    <IntlMessages id={`Www.Meta.sitename`} />
                  </label>
                  <div className="col-8">
                    <input
                      type="text"
                      name={"sitename"}
                      value={_.get(metadata, "sitename") || ""}
                      onChange={changeMeta}
                      className="form-control react-form-input"
                      autoComplete="off"
                    />
                  </div>
                </div>

                {[
                  "index",
                  "channels",
                  "join",
                  "ad",
                  "about",
                  "account",
                  "specials",
                  "onboard",
                  "favorites",
                ].map((name, index) => {
                  const title = `${name}.title`;
                  const description = `${name}.description`;
                  const keywords = `${name}.keywords`;
                  const file = `${name}.fileId`;
                  return (
                    <div key={index}>
                      <h3>
                        <IntlMessages id={`Www.Meta.${name}`} />
                      </h3>
                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Meta.title`} />
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            name={title}
                            value={_.get(metadata, title) || ""}
                            onChange={changeMeta}
                            className="form-control react-form-input"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Meta.description`} />
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            name={description}
                            value={_.get(metadata, description) || ""}
                            onChange={changeMeta}
                            className="form-control react-form-input"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="form-group row" key={index}>
                        <label className="col-4 col-form-label">
                          <IntlMessages id={`Www.Meta.keywords`} />
                        </label>
                        <div className="col-8">
                          <input
                            type="text"
                            name={keywords}
                            value={_.get(metadata, keywords) || ""}
                            onChange={changeMeta}
                            className="form-control react-form-input"
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-4 col-form-label">
                          <IntlMessages id="Www.Meta.image" />
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(file)}
                            imagesOnly="true"
                            multiple={false}
                          />
                        </label>
                        <div className="col-8">
                          {_.get(metadata, file) ? (
                            <ImagePreview
                              file={{ _id: _.get(metadata, file) }}
                              deleteHandler={() => {
                                changeMeta({ target: { name: file, value: null } });
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {
  getMetaData: api.get(apiUrls.metadata),
  saveMetaData: api.patch(apiUrls.metadata),
})(MetaData);
