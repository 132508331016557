import _ from "lodash";
import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";

export const ChannelsTranslationsParams = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  const format = "HH:mm";

  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1 border border-1 rounded py-2" key={index}>
            <div className="col-11">
              <div className="row mb-1">
                <div className="col-6">
                  <label className="mr-2">{intl.formatMessage({ id: "time" })}</label>
                  <DatePicker
                    selected={elem.time ? moment(elem.time, format).toDate() : ""}
                    onChange={(date) => {
                      Object.assign(values[index], { time: moment(date).format(format) });
                      changeValues(values);
                    }}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={intl.formatMessage({ id: "time" })}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-6">
                  <div className="row mb-1">
                    <div className="col-7 my-auto ">
                      {/* <label className="mr-2"> */}
                      {intl.formatMessage({ id: "Channels.Translations.params.minScore" })}
                      {/* </label> */}
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="minScore"
                        className="form-control"
                        value={elem.minScore || ""}
                        onChange={({ target }) => {
                          Object.assign(values[index], { [target.name]: +target.value });
                          changeValues(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row mb-1">
                    <div className="col-8 my-auto ">
                      {/* <label className="mr-2"> */}
                      {intl.formatMessage({ id: "Channels.Translations.params.lastHours" })}
                      {/* </label> */}
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        name="lastHours"
                        className="form-control"
                        value={elem.lastHours || ""}
                        onChange={({ target }) => {
                          Object.assign(values[index], { [target.name]: +target.value });
                          changeValues(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-1 text-right">
              <ChannelsTranslationsParams.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

ChannelsTranslationsParams.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat({}));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

ChannelsTranslationsParams.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default ChannelsTranslationsParams;
