import _, { every } from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import { FloatChannels } from "components/special/contents";

export const CategoriesWithScore = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  return (
    <div>
      {_.map(values, (elem, index) => {
        return (
          <div className="row mb-1" key={index}>
            <div className="col-10">
              <div className="row">
                <div className="col-6">
                  <div className="form-group row">
                    <label className="col-6 col-form-label">
                      {intl.formatMessage({ id: "OpenAI.Schedules.channel" })}
                    </label>
                    <div className="col-6">
                      <FloatChannels
                        _id={elem.channelId}
                        query={{ everything: false }}
                        multi={false}
                        showTitle={true}
                        showCount={false}
                        className="btn-sm"
                        successHandler={([channelId]) => {
                          Object.assign(values[index], { channelId });
                          changeValues(values);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <input
                    type="number"
                    name="minScore"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "OpenAI.Schedules.minScore",
                    })}
                    value={elem.minScore || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col text-right">
              <CategoriesWithScore.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

CategoriesWithScore.AddButton = (props) => {
  const { values = [], changeValues } = props;
  // const addValue = () => {
  //   changeValues(values.concat([{}]));
  // };
  const valuesId = _.map(values, (value) => value.channelId);
  const addValues = (channelsId = []) => {
    changeValues(
      values.concat(
        ...channelsId
          .filter((channelId) => !valuesId.includes(channelId))
          .map((channelId) => ({ channelId }))
      )
    );
  };

  return (
    <FloatChannels
      _id={valuesId}
      multi={true}
      showTitle={false}
      showCount={false}
      query={{ everything: false }}
      icon="fa-plus"
      className={`btn-sm btn-success ${props.className}`}
      successHandler={addValues}
    />

    // <button className={`btn btn-success ${props.className}`} onClick={addValue}>
    //   <i className="fa fa-plus" />
    // </button>
  );
};

CategoriesWithScore.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default CategoriesWithScore;
