import { path as parentPath } from "../init";

export const path = "casts";

const urlPrefix = "/api/openai/casts";

export const apiUrls = {
  casts: `${urlPrefix}`,
  castInfo: `${urlPrefix}/cast_{castId}`,
  castPublish: `${urlPrefix}/cast_{castId}/publish`,
  castReject: `${urlPrefix}/cast_{castId}/reject`,
  castCancel: `${urlPrefix}/cast_{castId}/cancel`,
  castRestore: `${urlPrefix}/cast_{castId}/restore`,
  castMessages: `${urlPrefix}/cast_{castId}/messages`,
  castCompilation: `${urlPrefix}/cast_{castId}/compilation`,
  castOrderedMessages: `${urlPrefix}/cast_{castId}/messages/ordered`,
  castSkippedMessages: `${urlPrefix}/cast_{castId}/messages/skipped`,
  castDeletedMessages: `${urlPrefix}/cast_{castId}/messages/deleted`,
  castDeleteMessages: `${urlPrefix}/cast_{castId}/deleteMessages`,
  summaryNotifications: `${urlPrefix}/cast_{castId}/sendSummaryNotifications`,
  superCover: `${urlPrefix}/cast_{castId}/superCover`,
  castIncludeMessages: `${urlPrefix}/cast_{castId}/includeMessages`,
  castNotDuplicateMessages: `${urlPrefix}/cast_{castId}/notDuplicateMessages`,
  castRefreshCover: `${urlPrefix}/cast_{castId}/refreshCover`,
  castRefreshIntro: `${urlPrefix}/cast_{castId}/refreshIntro`,
  castScoringText: `${urlPrefix}/cast_{castId}/setScoringText`,
  castSetCover: `${urlPrefix}/cast_{castId}/setCover`,
  castThreadLogs: `${urlPrefix}/cast_{castId}/threadLogs`,
  editMessage: `/api/channels/channel_{channelId}/messages/message_{messageId}`,
};

export const infoLink = (cast) => {
  return `${parentPath}/${path}?castId=${cast._id}`;
};

export const formLink = (cast) => {
  return `${parentPath}/form`;
};
