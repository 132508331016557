/** управление каналами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import qs from "query-string";
import { history } from "redux/store";

import LogsTable from "./Table";
// import ThreadRunsTable from "./Runs";
import { path } from "./init";

const ChannelTranslationLogs = (props) => {
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery({ ...qs.parse(history.location.search) });
  }, [history.location]);

  return (
    <div>
      <LogsTable {...props} />
      {/* {query.threadId ? <ThreadRunsTable {...props} /> : <LogsTable {...props} />} */}
    </div>
  );
};
ChannelTranslationLogs.path = path;
export default ChannelTranslationLogs;
