import { apiUrls as parentUrls, infoLink as parentInfo } from "../init";

export const path = "logs";

export const apiUrls = {
  list: `${parentUrls.info}/logs`,
  info: `${parentUrls.info}/logs/log_{logId}`,
};

export const infoLink = (log) => {
  return `${parentInfo({ _id: log.translationId })}/${path}?logId=${log._id}`;
};

export const formLink = (log) => {
  return `${infoLink(log)}/form`;
};
