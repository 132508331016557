import _ from "lodash";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useIntl } from "react-intl";

import IntlMessages from "util/intlMessages";

const PickTime = (props) => {
  const { attrName, data, setData } = props;
  const intl = useIntl();

  const format = "HH:mm";

  return (
    <>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id={`Www.Settings.${attrName}`} />
        </label>
        <div className="col-8">
          <DatePicker
            selected={data[attrName] ? moment(data[attrName], format).toDate() : ""}
            onChange={(date) => {
              setData({ ...data, [attrName]: moment(date).format(format) });
            }}
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={intl.formatMessage({ id: "time" })}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </div>
      </div>
    </>
  );
};

export default PickTime;
