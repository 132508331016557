import _ from "lodash";
import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import DatePicker from "react-datepicker";
import { FloatChannels, FloatDataNodes } from "components/special/contents";

const ContentData = (props) => {
  const { content, setContent } = props;
  const format = "HH:mm";
  const intl = useIntl();

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.Translations.channel" />
        </label>
        <div className="col-8">
          <FloatChannels
            _id={content.channelId}
            query={{ everything: false }}
            multi={false}
            showTitle={true}
            showCount={false}
            className="btn-sm mr-1"
            successHandler={([channelId]) => {
              setContent({ ...content, channelId });
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Channels.Translations.dataNode" />
        </label>
        <div className="col-8">
          <FloatDataNodes
            _id={content.dataNodeId}
            query={{ everything: false }}
            multi={false}
            title={(dataNode) => `${dataNode.ident}/${dataNode.ip}`}
            showTitle={true}
            showCount={false}
            className="btn-sm mr-1"
            successHandler={([dataNodeId]) => {
              setContent({ ...content, dataNodeId });
            }}
          />
        </div>
      </div>

      {/* 
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="CastsTranslations.time" />
        </label>
        <div className="col-8">
          <DatePicker
            selected={content.time ? moment(content.time, format).toDate() : ""}
            onChange={(date) => {
              setContent({ ...content, time: moment(date).format(format) });
            }}
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={intl.formatMessage({ id: "time" })}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </div>
      </div>
       */}
    </div>
  );
};

export default connect(null, {})(ContentData);
