/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { adminAPI } from "redux/api";
import { FloatModal } from "components/special";
import { getOnlineStatus } from "../../../variables";

const { api } = adminAPI.actions;

const FloatDataNodesModal = (props) => {
  return (
    <FloatModal
      header={<IntlMessages id="DataNodes" />}
      {...props}
      head={(searchDataNode, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="DataNodes.ident" />
            </th>
            <th>
              <IntlMessages id="DataNodes.ip" />
            </th>
            <th style={{ width: "30px" }}></th>
          </>
        );
      }}
      row={(dataNode, clickHandler) => {
        const onlineClass = getOnlineStatus(dataNode.lastOnline);
        const className = `${!dataNode.enabled ? "text-strike" : ""} ${onlineClass}`;
        return (
          <>
            <td onClick={clickHandler} className={className}>
              {dataNode.ident}
            </td>
            <td>{dataNode.ip}</td>
          </>
        );
      }}
    />
  );
};

const FloatDataNodes = (props) => {
  return <FloatDataNodesModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/references/dataNodes"),
})(FloatDataNodes);
