import moment from "moment";

export const limit = 100;

export const getOnlineStatus = (dateTime) => {
  if (moment(dateTime).add(2, "minute").toDate() < new Date()) {
    return "text-danger";
  } else {
    return "text-success";
  }
};
