import _ from "lodash";
import React from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import DatePicker from "react-datepicker";
import { SwitchValue } from "components/special/forms";
import {
  TelegramPublishFormatSelector,
  ImagePreview,
  Text2Mp3Button,
  Uploader,
} from "components/standart";
import { FloatPublishChannels } from "components/special/contents";

export const RuntimeArrayValues = (props) => {
  const { values = [], changeValues } = props;
  const intl = useIntl();
  const format = "HH:mm";

  return (
    <div>
      {_.map(values, (elem, index) => {
        const showOldTelegramSettings = false;
        return (
          <div className="row mb-1 border border-1 rounded py-2" key={index}>
            <div className="col-11">
              <div className="row mb-1">
                <div className="col-3">
                  <DatePicker
                    selected={elem.time ? moment(elem.time, format).toDate() : ""}
                    onChange={(date) => {
                      Object.assign(values[index], { time: moment(date).format(format) });
                      changeValues(values);
                    }}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption={intl.formatMessage({ id: "time" })}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                  />
                </div>

                <div className="col-7">
                  <input
                    type="string"
                    name="timeTitle"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "OpenAI.Schedules.runtime.timeTitle" })}
                    value={elem.timeTitle || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-6">
                  <input
                    type="string"
                    name="periodTitle"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "OpenAI.Schedules.runtime.periodTitle" })}
                    value={elem.periodTitle || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
                <div className="col-6">
                  <input
                    type="string"
                    name="castTitle"
                    className="form-control"
                    placeholder={intl.formatMessage({ id: "OpenAI.Schedules.runtime.castTitle" })}
                    value={elem.castTitle || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>
              {/* 
              <div className="row mb-1">
                <div className="col">
                  <input
                    type="text"
                    name="title"
                    placeholder={intl.formatMessage({ id: "OpenAI.Schedules.runtime.title" })}
                    value={elem.title || ""}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.value });
                      changeValues(values);
                    }}
                    className="form-control"
                    autoComplete="off"
                  />
                </div>

                <div className="col-1">
                  <Text2Mp3Button
                    value={elem?.title}
                    onSuccess={(file) => {
                      Object.assign(values[index], { fileId: file._id });
                      changeValues(values);
                    }}
                  />
                </div>

                <div className="col">
                  {elem.fileId && (
                    <AudioPreview
                      file={{ _id: elem.fileId }}
                      deleteHandler={() => {
                        Object.assign(values[index], { fileId: null });
                        changeValues(values);
                      }}
                    />
                  )}
                </div>
              </div>
               */}
              <div className="row">
                <label className="col-6 col-form-label">
                  {intl.formatMessage({ id: "OpenAI.Schedules.runtime.includePrevious" })}
                </label>
                <div className="col my-auto">
                  <SwitchValue
                    name="includePrevious"
                    value={elem.includePrevious}
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.checked });
                      changeValues(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-6 col-form-label">
                  {intl.formatMessage({ id: "OpenAI.Schedules.runtime.telegramPublish" })}
                </label>
                <div className="col my-auto">
                  <SwitchValue
                    value={elem.telegramPublish}
                    name="telegramPublish"
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.checked });
                      changeValues(values);
                    }}
                    // onChange={changeContent}
                  />
                </div>
              </div>

              {elem.telegramPublish ? (
                <>
                  <div className="form-group row">
                    <label className="col-6 col-form-label">
                      {intl.formatMessage({ id: "OpenAI.Schedules.runtime.telegramCollapse" })}
                    </label>
                    <div className="col my-auto">
                      <SwitchValue
                        value={elem.telegramCollapse}
                        name="telegramCollapse"
                        onChange={({ target }) => {
                          Object.assign(values[index], { [target.name]: target.checked });
                          changeValues(values);
                        }}
                        // onChange={changeContent}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-6 col-form-label">
                      {intl.formatMessage({ id: "telegramPublishChannel" })}
                    </label>
                    <div className="col my-auto">
                      <FloatPublishChannels
                        _id={elem.publishChannelId}
                        multi={false}
                        showTitle={true}
                        showCount={false}
                        className="btn-sm mr-1"
                        successHandler={([publishChannelId]) => {
                          Object.assign(values[index], { publishChannelId });
                          changeValues(values);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-6 col-form-label">
                      {intl.formatMessage({ id: "telegramPublishFormat" })}
                    </label>
                    <div className="col my-auto">
                      <TelegramPublishFormatSelector
                        value={elem.telegramPublishFormat}
                        setValue={({ target }) => {
                          Object.assign(values[index], { [target.name]: target.value });
                          changeValues(values);
                        }}
                        name="telegramPublishFormat"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <label className="col-6 col-form-label">
                          {intl.formatMessage({
                            id: "OpenAI.Schedules.runtime.templatePostFileId",
                          })}
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={([file]) => {
                              Object.assign(values[index], { templatePostFileId: file._id });
                              changeValues(values);
                            }}
                            imagesOnly="true"
                            multiple={false}
                          />
                        </label>
                        <div className="col my-auto">
                          {elem.templatePostFileId ? (
                            <ImagePreview
                              file={{ _id: elem.templatePostFileId }}
                              deleteHandler={() => {
                                Object.assign(values[index], { templatePostFileId: null });
                                changeValues(values);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <SetPostCoverAndStory {...{ elem, index, changeValues, values }} /> */}
                </>
              ) : null}

              <div className="form-group row">
                <label className="col-6 col-form-label">
                  {intl.formatMessage({ id: "OpenAI.Schedules.runtime.telegramMakeStory" })}
                </label>
                <div className="col my-auto">
                  <SwitchValue
                    value={elem.telegramMakeStory}
                    name="telegramMakeStory"
                    onChange={({ target }) => {
                      Object.assign(values[index], { [target.name]: target.checked });
                      changeValues(values);
                    }}
                    // onChange={changeContent}
                  />
                </div>
              </div>
              {false && elem.telegramMakeStory ? (
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <label className="col-6 col-form-label">
                        {intl.formatMessage({
                          id: "OpenAI.Schedules.runtime.templateStoryFileId",
                        })}
                        <Uploader
                          className="btn-success btn-sm pull-right"
                          successHandler={([file]) => {
                            Object.assign(values[index], { templateStoryFileId: file._id });
                            changeValues(values);
                          }}
                          imagesOnly="true"
                          multiple={false}
                        />
                      </label>
                      <div className="col my-auto">
                        {elem.templateStoryFileId ? (
                          <ImagePreview
                            file={{ _id: elem.templateStoryFileId }}
                            deleteHandler={() => {
                              Object.assign(values[index], { templateStoryFileId: null });
                              changeValues(values);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-1 text-right">
              <RuntimeArrayValues.RemoveButton {...props} index={index} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

RuntimeArrayValues.AddButton = (props) => {
  const { values = [], changeValues } = props;
  const addValue = () => {
    changeValues(values.concat({}));
  };
  return (
    <button className={`btn btn-success ${props.className}`} onClick={addValue}>
      <i className="fa fa-plus" />
    </button>
  );
};

RuntimeArrayValues.RemoveButton = (props) => {
  const { values = [], changeValues } = props;
  const removeValue = () => {
    changeValues(values.filter((elem, index) => index !== props.index));
  };
  return (
    <button className={`btn btn-sm btn-danger`} onClick={removeValue}>
      <i className="fa fa-minus" />
    </button>
  );
};

export default RuntimeArrayValues;
