/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { adminAPI } from "redux/api";
import { useIntl } from "react-intl";
import { Finder, FilterButton, Pager, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit, getOnlineStatus } from "variables";
import { FloatChannels, FloatDataNodes } from "components/special/contents";

import { apiUrls, infoLink } from "./init";
import { FormButton } from "./Form";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [updated, setUpdated] = useState(null);

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    (query.limit || updated) && props.getContent({ query: { ...query } }, { onSuccess, onRequest });
  }, [query, updated]);

  return (
    <>
      <Table responsive>
        <thead className="-text-nowrap">
          <tr>
            <th className="col-1">
              <FloatChannels
                _id={query.channelId}
                query={{ everything: false }}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(channelId) => {
                  setQuery({ ...query, channelId });
                }}
              />
              <IntlMessages id="Channels.Translations.channel" />
            </th>
            <th className="col-1">
              <FloatDataNodes
                _id={query.dataNodeId}
                query={{ everything: false }}
                multi={true}
                showTitle={false}
                showCount={true}
                className="btn-sm mr-1"
                successHandler={(dataNodeId) => {
                  setQuery({ ...query, dataNodeId });
                }}
              />
              <IntlMessages id="Channels.Translations.dataNode" />
            </th>

            <th className="col-1">
              <IntlMessages id="Channels.Translations.serverUrl" />
            </th>

            <th className="col-1">
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}

        <tbody>
          {_.map(contentData.data, (content, index) => (
            <tr className={`${!content.enabled ? "text-strike" : ""}`} key={index}>
              <td>
                <Link to={infoLink(content)}>{content.channel?.name}</Link>
              </td>
              <td className={getOnlineStatus(content.dataNode?.lastOnline)}>
                {content.dataNode?.ident} {content.dataNode?.ip}
              </td>
              <td>{_.map(content.rtmps, (value) => value.serverUrl).join("; ")}</td>

              <td>
                <FormButton {...content} />
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.list),
})(ContentTable);
