import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { connect } from "react-redux";
import { Alert } from "reactstrap";

import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "./init";
const { api } = adminAPI.actions;

const AdvertsSettings = (props) => {
  const { url } = useRouteMatch();
  const { breadCrumbs } = props;
  const [settings, setSettings] = useState({});
  useEffect(() => {
    props.getSettings(
      {},
      {
        onRequest() {
          setSettings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setSettings(body);
        },
      }
    );
  }, []);

  breadCrumbs.push({
    title: <IntlMessages id="Adverts.Settings" />,
    url,
  });

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeSettings = setOnChange(settings, setSettings);

  // аттрибуты, которые необходимо отобразить
  const attrs = [
    "limit",
    "offset",
    // "maxSeconds", "daysInterval",
    "maxRepeats",
  ];

  const saveSettingsHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.saveSettings(
      {
        body: { ...settings },
      },
      {
        onSuccess(body) {
          setSettings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  return (
    <>
      <PageTitle {...breadCrumbs} />

      {settings._id ? (
        <div className="row">
          <div className="col-12 -col-lg-6">
            <div className="roe-card-style">
              <div className="roe-card-header"></div>
              <div className="roe-card-body">
                <div ref={successAlertRef}>
                  <Alert
                    className="alert-success"
                    isOpen={dataSaved}
                    toggle={() => setDataSaved(false)}
                  >
                    <IntlMessages id="saved" />
                  </Alert>
                </div>

                {attrs.map((name, index) => {
                  return (
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Adverts.Settings.${name}`} />
                      </label>
                      <div className="col-8">
                        <input
                          type="number"
                          name={name}
                          value={+settings[name] || ""}
                          onChange={changeSettings}
                          className="form-control react-form-input"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="form-group row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="form-control c-primary"
                      onClick={saveSettingsHandler}
                    >
                      <IntlMessages id="save" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingIcon />
      )}
    </>
  );
};

export default connect(null, {
  getSettings: api.get(apiUrls.settings),
  saveSettings: api.patch(apiUrls.settings),
})(AdvertsSettings);
